<template>
  <div class="jiazhang">
    <headerNav></headerNav>
    <el-carousel :interval="5000" arrow="always" height="750px">
      <el-carousel-item v-for="item in bannerList" :key="item">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>

    <div class="titleBj">
      <div class="titleWz">家长服务</div>
    </div>
    <div class="one">
      <div class="oneLeft">
        <p class="title">个性化关怀</p>
      </div>
      <div class="oneRight">
        <div class="wzList">
          <p class="text">
            团队始终以每个学生的个性和需求为重点。<br />
            了解每位学生的独特性格、兴趣和挑战，
            <br />并制定相应的教育计划， <br />以确保他们充分发展自己的潜力。
          </p>
        </div>
      </div>
    </div>
    <div class="one">
      <div class="twoRight">
        <div class="wzList">
          <p class="text">
            与家长的紧密合作对于学生的成功至关重要。<br />
            定期举行家长会议，提供孩子在学校的表现反馈，讨论学术进展和发展计划。<br />

            此外，还通过电子邮件、电话和在线平台保持与家长的沟通，<br />

            以便及时解答问题和提供支持。
          </p>
        </div>
      </div>
      <div class="twoLeft">
        <p class="title">定期沟通</p>
      </div>
    </div>
    <div class="one">
      <div class="oneLeft">
        <p class="title">课程支持</p>
      </div>
      <div class="oneRight">
        <div class="wzList">
          <p class="text">
            学术团队随时为学生提供额外的课程支持。<br />
            如果学生在特定领域需要额外的帮助，<br />

            我们将为他们提供一对一指导或小组辅导，<br />
            以确保他们充分理解和掌握课程内容。
          </p>
        </div>
      </div>
    </div>
    <div class="one">
      <div class="twoRight">
        <div class="wzList">
          <p class="text">
            鼓励学生积极参与各种课外活动，<br />
            以培养他们的兴趣和才能。
            <br />提供丰富多彩的社团、俱乐部和比赛，<br />
            为学生提供了展示自己技能和兴趣的机会。
          </p>
        </div>
      </div>
      <div class="twoLeft">
        <p class="title">课外活动</p>
      </div>
    </div>
    <div class="one">
      <div class="oneLeft">
        <p class="title">家庭支持资源</p>
      </div>
      <div class="oneRight">
        <div class="wzList">
          <p class="text">
            我们为家长提供有关教育和家庭支持的资源，
            <br />包括教育材料、亲子活动和工作坊。
            <br />鼓励家长积极参与孩子的教育，
            <br />共共同创造一个支持性和富有爱心的家庭环境。
          </p>
        </div>
      </div>
    </div>
    <div class="one">
      <div class="twoRight">
        <div class="wzList">
          <p class="text">
            学生的安全是我们的首要任务。
            <br />采取严格的安全措施，确保校园环境安全无虞。
            <br />定期进行安全演习， <br />并配备专业的安保人员。
          </p>
        </div>
      </div>
      <div class="twoLeft">
        <p class="title">校园安全</p>
      </div>
    </div>
    <div class="one">
      <div class="oneLeft">
        <p class="title">反馈和建议</p>
      </div>
      <div class="oneRight">
        <div class="wzList">
          <p class="text">
            重视学生和家长的反馈和建议。<br />
            如果您有任何疑虑、建议或需求，鼓励您与我们联系。
            <br />我们将倾听您的声音，并不断改进我们的服务，
            <br />以满足您的期望。
          </p>
        </div>
      </div>
    </div>
    <div class="two">
      <p class="text2">
        在燕京雍阳，不仅是一所幼儿园，<br />
        更是一个温馨的社区，一个充满关爱和支持的大家庭。
        <br />我们期待着与您建立紧密的合作关系，
        <br />共同努力为每位学生创造一个成功和幸福的未来。
      </p>
    </div>

    <div class="empty"></div>
    <footerBtm></footerBtm>
  </div>
</template>

<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";
export default {
  name: "jiazhang",
  components: { headerNav, footerBtm },
  data() {
    return {
      bannerList: [],
    };
  },
  mounted() {
    this.api.allPage({ name: '家长服务' }).then((res) => {})
    // banner
    this.api.banner({ remark1: 3 }).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].img = JSON.parse(res.data[i].img);
      }
      this.bannerList = res.data;
    });
  },
  methods: {},
};
</script>
<style scoped>
.el-carousel,
.el-carousel__item,
.el-carousel img {
  width: 100%;
}
.titleBj {
  width: 922px;
  height: 140px;
  margin: 0 auto;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/jiazhang/pcJzfuTitle.png");
  background-size: 100% 100%;
  margin-top: 30px;
  margin-bottom: 40px;
}

.titleWz {
  width: 240px;
  height: 100px;
  font-size: 60px;
  font-weight: 500;
  color: #25ae85;
  line-height: 150px;
  margin: 0 auto;
}

.banner {
  padding-top: 90px;
  width: 1920px;
  height: 750px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

.one {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  padding-top: 40px;
}

.oneLeft {
  width: 300px;
  height: 300px;
  background: #ffe500;
  border-radius: 40px 40px 40px 40px;
  opacity: 1;
}

.oneRight {
  width: 980px;
  height: 300px;
  background: rgba(255, 229, 0, 0.4);
  border-radius: 40px 40px 40px 40px;
  opacity: 1;
}

.twoLeft {
  width: 300px;
  height: 300px;
  background: #25ae85;
  border-radius: 40px 40px 40px 40px;
  opacity: 1;
}

.twoRight {
  width: 980px;
  height: 300px;
  background: #25ae85;
  border-radius: 40px 40px 40px 40px;
  opacity: 1;
}
.twoRight .text {
  color: #ffffff;
}

.title {
  width: 100%;
  height: 40px;
  font-size: 40px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #252525;
  line-height: 300px;
  margin: 0 auto;
  text-align: center;
}
.twoLeft .title {
  color: #ffffff;
}

.wzList {
  width: 686px;
  height: 100%;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 0 auto;
  padding: 54px 0;
  box-sizing: border-box;
  line-height: 48px;
  text-align: center;
}
.text {
  width: 686px;
  height: 100%;
  font-size: 19px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  text-align: center;
}
.two {
  background-image: url(https://lwjy.oss-cn-beijing.aliyuncs.com/school/xuxian.png);
  background-size: 100% 100%;
  width: 1108px;
  height: 429px;
  margin: 0 auto;
  margin-top: 103px;
}
.text2 {
  width: 768px;
  height: 256px;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  line-height: 56px;
  margin: 0 auto;
  padding-top: 87px;
  text-align: center;
}
.empty {
  height: 86px;
}
@media screen and (max-width: 1440px) {
  div /deep/ .el-carousel__container {
    height: 563px !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
}
@media (min-width: 1281px) and (max-width: 1366px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 534px;
  }
  div /deep/ .el-carousel__container {
    height: 534px !important;
  }
  .titleBj{
    width: 910px;
    margin-top: 57px;
    height: 100px;
    margin-bottom: 29px;
  }
  .titleWz{
    font-size: 43px;
    text-align: center;
  }
  .one{
    width: 910px;
    padding-top: 28px;
  }
  .oneLeft{
    width: 213px;
    height: 213px;
    border-radius: 28px;
  }
  .title{
    line-height: 213px;
    font-size: 28px;
  }
  .oneRight{
    width: 697px;
    height: 213px;
    border-radius: 28px;
  }
  .wzList{
    padding: 38px 0;
    line-height: 34px;
    font-size: 17px;
  }

  .twoRight{
    width: 697px;
    height: 213px;
    border-radius: 28px;
  }
  .twoLeft{
    width: 213px;
    height: 213px;
    border-radius: 28px;
  }

  .two{
    width: 788px;
    height: 305px;
    margin-top: 78px;
  }
  .text2{
    width: 560px;
    height: 182px;
    font-size: 23px;
    line-height: 46px;
    padding-top: 62px;
    text-align: left;
  }
  .empty{
    height: 60px;
  }
}

@media (min-width: 1080px) and (max-width: 1280px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 500px;
  }
  div /deep/ .el-carousel__container {
    height: 500px !important;
  }
  .titleBj{
    width: 852px;
    margin-top: 53px;
    height: 93px;
    margin-bottom: 29px;
  }
  .titleWz{
    font-size: 40px;
    text-align: center;
  }
  .one{
    width: 853px;
    padding-top: 28px;
  }
  .oneLeft{
    width: 200px;
    height: 200px;
    border-radius: 28px;
  }
  .title{
    line-height: 200px;
    font-size: 27px;
  }
  .oneRight{
    width: 653px;
    height: 200px;
    border-radius: 28px;
  }
  .wzList{
    padding: 36px 0;
    line-height: 32px;
    font-size: 16px;
  }

  .twoRight{
    width: 653px;
    height: 200px;
    border-radius: 28px;
  }
  .twoLeft{
    width: 200px;
    height: 200px;
    border-radius: 28px;
  }

  .two{
    width: 740px;
    height: 286px;
    margin-top: 73px;
  }
  .text2{
    width: 512px;
    height: 171px;
    font-size: 21px;
    line-height: 43px;
    padding-top: 58px;
    text-align: left;
  }
  .empty{
    height: 57px;
  }
}
@media screen and (max-width: 750px) {
  div /deep/ .el-carousel__container {
    height: 146px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }
  .titleBj {
    width: 100%;
    height: 47px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/phoneAboutus/phoneJiazhangTitle.png");
    background-size: 100% 100%;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .one {
    padding-top: 10px;
  }
  .titleWz {
    height: 47px;
    font-size: 20px;
    line-height: 67px;
    text-align: center;
  }
  .oneLeft {
    width: 83px;
    height: 130px;
    border-radius: 13px;
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .title {
    width: 16px;
    height: 100px;
    font-size: 14px;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  .oneRight {
    width: 260px;
    height: 130px;
    border-radius: 13px;
  }
  .text {
    width: 260px;
    height: 130px;
    line-height: 16px;
    font-size: 8px;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .twoRight {
    width: 260px;
    height: 130px;
    border-radius: 13px;
  }
  .twoLeft {
    width: 83px;
    height: 130px;
    border-radius: 13px;
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .wzList {
    padding: 0;
  }
  .two {
    width: 100%;
    height: 152px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text2 {
    width: 288px;
    height: 96px;
    font-size: 12px;
    line-height: 24px;
    padding-top: 0;
    text-align: center;
    justify-content: center;
  }
  .empty {
    height: 17px;
  }
}
</style>
