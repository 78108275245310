var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jiazhang"},[_c('headerNav'),_c('el-carousel',{attrs:{"interval":5000,"arrow":"always","height":"750px"}},_vm._l((_vm.bannerList),function(item){return _c('el-carousel-item',{key:item},[_c('img',{attrs:{"src":item.img,"alt":""}})])}),1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"empty"}),_c('footerBtm')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleBj"},[_c('div',{staticClass:"titleWz"},[_vm._v("家长服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('div',{staticClass:"oneLeft"},[_c('p',{staticClass:"title"},[_vm._v("个性化关怀")])]),_c('div',{staticClass:"oneRight"},[_c('div',{staticClass:"wzList"},[_c('p',{staticClass:"text"},[_vm._v(" 团队始终以每个学生的个性和需求为重点。"),_c('br'),_vm._v(" 了解每位学生的独特性格、兴趣和挑战， "),_c('br'),_vm._v("并制定相应的教育计划， "),_c('br'),_vm._v("以确保他们充分发展自己的潜力。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('div',{staticClass:"twoRight"},[_c('div',{staticClass:"wzList"},[_c('p',{staticClass:"text"},[_vm._v(" 与家长的紧密合作对于学生的成功至关重要。"),_c('br'),_vm._v(" 定期举行家长会议，提供孩子在学校的表现反馈，讨论学术进展和发展计划。"),_c('br'),_vm._v(" 此外，还通过电子邮件、电话和在线平台保持与家长的沟通，"),_c('br'),_vm._v(" 以便及时解答问题和提供支持。 ")])])]),_c('div',{staticClass:"twoLeft"},[_c('p',{staticClass:"title"},[_vm._v("定期沟通")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('div',{staticClass:"oneLeft"},[_c('p',{staticClass:"title"},[_vm._v("课程支持")])]),_c('div',{staticClass:"oneRight"},[_c('div',{staticClass:"wzList"},[_c('p',{staticClass:"text"},[_vm._v(" 学术团队随时为学生提供额外的课程支持。"),_c('br'),_vm._v(" 如果学生在特定领域需要额外的帮助，"),_c('br'),_vm._v(" 我们将为他们提供一对一指导或小组辅导，"),_c('br'),_vm._v(" 以确保他们充分理解和掌握课程内容。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('div',{staticClass:"twoRight"},[_c('div',{staticClass:"wzList"},[_c('p',{staticClass:"text"},[_vm._v(" 鼓励学生积极参与各种课外活动，"),_c('br'),_vm._v(" 以培养他们的兴趣和才能。 "),_c('br'),_vm._v("提供丰富多彩的社团、俱乐部和比赛，"),_c('br'),_vm._v(" 为学生提供了展示自己技能和兴趣的机会。 ")])])]),_c('div',{staticClass:"twoLeft"},[_c('p',{staticClass:"title"},[_vm._v("课外活动")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('div',{staticClass:"oneLeft"},[_c('p',{staticClass:"title"},[_vm._v("家庭支持资源")])]),_c('div',{staticClass:"oneRight"},[_c('div',{staticClass:"wzList"},[_c('p',{staticClass:"text"},[_vm._v(" 我们为家长提供有关教育和家庭支持的资源， "),_c('br'),_vm._v("包括教育材料、亲子活动和工作坊。 "),_c('br'),_vm._v("鼓励家长积极参与孩子的教育， "),_c('br'),_vm._v("共共同创造一个支持性和富有爱心的家庭环境。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('div',{staticClass:"twoRight"},[_c('div',{staticClass:"wzList"},[_c('p',{staticClass:"text"},[_vm._v(" 学生的安全是我们的首要任务。 "),_c('br'),_vm._v("采取严格的安全措施，确保校园环境安全无虞。 "),_c('br'),_vm._v("定期进行安全演习， "),_c('br'),_vm._v("并配备专业的安保人员。 ")])])]),_c('div',{staticClass:"twoLeft"},[_c('p',{staticClass:"title"},[_vm._v("校园安全")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('div',{staticClass:"oneLeft"},[_c('p',{staticClass:"title"},[_vm._v("反馈和建议")])]),_c('div',{staticClass:"oneRight"},[_c('div',{staticClass:"wzList"},[_c('p',{staticClass:"text"},[_vm._v(" 重视学生和家长的反馈和建议。"),_c('br'),_vm._v(" 如果您有任何疑虑、建议或需求，鼓励您与我们联系。 "),_c('br'),_vm._v("我们将倾听您的声音，并不断改进我们的服务， "),_c('br'),_vm._v("以满足您的期望。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"two"},[_c('p',{staticClass:"text2"},[_vm._v(" 在燕京雍阳，不仅是一所幼儿园，"),_c('br'),_vm._v(" 更是一个温馨的社区，一个充满关爱和支持的大家庭。 "),_c('br'),_vm._v("我们期待着与您建立紧密的合作关系， "),_c('br'),_vm._v("共同努力为每位学生创造一个成功和幸福的未来。 ")])])
}]

export { render, staticRenderFns }